import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Search from '../components/Search'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import Container from '../components/Container'
import Section from '../components/Section'

export const DiscoverPageTemplate = ({
  bannerimage,
  title,
  theme,
  section,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="discover" label="Discover" />
    <Container className="flex flex-col flex-col py-10 sm:py-12 md:py-16">
      <Section content={section} />
    </Container>
    <Container className="pb-12 sm:pb-16 md:pb-20">
      <Search />
    </Container>
  </>
)

DiscoverPageTemplate.propTypes = {
  bannerimage: PropTypes.object,
  title: PropTypes.string,
  theme: PropTypes.string,
  section: PropTypes.object,
}

const DiscoverPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <DiscoverPageTemplate
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        section={frontmatter.section}
      />
    </Layout>
  )
}

DiscoverPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default DiscoverPage

export const pageQuery = graphql`
  query DiscoverPageTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section {
          title
          body
        }
      }
    }
  }
`
