import React, { useState, useEffect, useRef } from 'react'
import { render } from 'react-dom'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Formik, Form, Field, FieldArray } from 'formik'
import Component from '@reach/component-component'
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import elasticlunr, { Index } from 'elasticlunr'
import _ from 'lodash'
// import { Trail } from 'react-spring/renderprops';
import { useTrail, animated } from 'react-spring'
import SVGMagnifyingGlass from './SVG/SVGMagnifyingGlass'
import { ResourceTemplate } from '../templates/ResourceTemplate'
import CommonLink from './CommonLink'

// Sets the checkbox lists (this will need to be generated by loop)
const themes = [
  {
    id: 'An Efficient and Predictable Development Pattern',
    name: 'An Efficient and Predictable Development Pattern',
  },
  {
    id: 'A Connected Multimodal Region',
    name: 'A Connected Multimodal Region',
  },
  {
    id: 'A Safe and Resilient Natural and Built Environment',
    name: 'A Safe and Resilient Natural and Built Environment',
  },
  {
    id: 'Healthy, Inclusive and Livable Communities',
    name: 'Healthy, Inclusive and Livable Communities',
  },
  {
    id: 'A Vibrant Regional Economy',
    name: 'A Vibrant Regional Economy',
  },
]

const initiatives = [
  { id: 'Idea Exchange', name: 'Idea Exchange' },
  { id: 'Performance Measures', name: 'Performance Measures' },
  { id: 'Urban Centers', name: 'Urban Centers' },
  {
    id: 'Sustainable Communities Initiative',
    name: 'Sustainable Communities Initiative',
  },
]

const plans = [
  { id: 'Metro Vision', name: 'Metro Vision' },
  { id: 'Regional Transportation Plan', name: 'Regional Transportation Plan' },
]

const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.offsetTop - 20,
    behavior: 'smooth',
  })

// Container component for passing in index to query, and the navigate/location from reach-router
const Search = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => {
      //console.log(data)
      return (
        <Location>
          {({ navigate, location }) => (
            <FormikSearch
              navigate={navigate}
              location={location}
              searchIndex={data.siteSearchIndex.index}
            />
          )}
        </Location>
      )
    }}
  />
)
// The default export for importing in other components / pages.
export default Search

// The actual search component receives props from the container component
const FormikSearch = props => {
  // Hooks based state to control the results object
  const [results, setResults] = useState([])
  const [currentPage, setCurrentPage] = useState() // Used to hoist state out of Formik
  const resultsRef = useRef(null)
  const resultsPerPage = 8
  const indexOfLastResult = currentPage * resultsPerPage
  const indexOfFirstResult = indexOfLastResult - resultsPerPage
  const currentResults = results.slice(indexOfFirstResult, indexOfLastResult)
  const trail = useTrail(currentResults.length, {
    from: { marginLeft: -30, opacity: 0 },
    to: { marginLeft: 0, opacity: 1 },
  })

  // Set the form values based on the URL. If the user navigates to querystring url directly correctly set the form and show the results. The location in this case is brought in from a prop on reach-routers location.
  const queryParameters = queryString.parse(props.location.search, {
    arrayFormat: 'index',
  })
  console.log(queryParameters);
  const initialStructure = {
    q: '',
    themes: [],
    plans: [],
    initiatives: [],
    sort: 'title',
    page: '1',
  }
  // We must set an initalStructure and merge the two objects to ensure that the initalValues contain both the props from location, or blank placeholders to avoid a undefined error.
  const initialValues = Object.assign(initialStructure, queryParameters)
  console.log(initialValues);
  // Load the index. The index is automatically generated by Gatsby at buildtime using the code in gatsby-config.js.
  const index = Index.load(props.searchIndex)

  // Sets the Elasticlunr user config. In this case it sets boolean to "AND" vs the default OR so that the list filters based on intersected filters. Think center of Venn Diagram. Expand deals with how the tokens are treated in the Stemmer process of the Lunr search.
  let configStr = null
  const userConfig = { bool: 'AND', expand: true }
  if (userConfig != null) {
    configStr = JSON.stringify(userConfig)
  }

  // This sets the up the configuration of elasticlunr for the whole app. Providing both ther user config provided directly above as well as getting the fields available for Index to search on later.
  const config = new elasticlunr.Configuration(
    configStr,
    index.getFields()
  ).get()

  // Default search object that represents the Elasticlunr results for each individual field search. Each of these object properties is then compared to one another to come up with a intersected list.
  let fieldSearchObject = initialStructure

  // FUNCTIONS START HERE!

  const search = values => {
    // console.log(index.documentStore.docs);
    // Start each search field results as all documents.
    let querySearchObj = index.documentStore.docs
    if (values.q) {
      const query = index.pipeline.run(elasticlunr.tokenizer(values.q))
      // Search the value of the query in title in return results
      querySearchObj = index.fieldSearch(query, 'title', config)
      // console.log("Query Found'");
    } else {
      // If user doesn't supply a query return all results to be used in intersection
      querySearchObj = index.documentStore.docs
      // console.log("No Query");
    }

    let themesSearchObj = index.documentStore.docs

    // Same principal as above, but check for length instead because we are expecting an array of values.
    if (values.themes.length) {
      const themes = index.pipeline.run(elasticlunr.tokenizer(values.themes))
      themesSearchObj = index.fieldSearch(themes, 'themes', config)
      // console.log("themes Found");
    }
    // If empty array return all results
    else {
      themesSearchObj = index.documentStore.docs
      // console.log("No themes");
    }

    let plansSearchObj = index.documentStore.docs

    // Same principal as above, but check for length instead because we are expecting an array of values.
    if (values.plans.length) {
      const plans = index.pipeline.run(elasticlunr.tokenizer(values.plans))
      plansSearchObj = index.fieldSearch(plans, 'plans', config)
      // console.log("plans Found");
    }
    // If empty array return all results
    else {
      plansSearchObj = index.documentStore.docs
      // console.log("No plans");
    }

    let initiativesSearchObj = index.documentStore.docs

    if (values.initiatives.length) {
      const initiatives = index.pipeline.run(
        elasticlunr.tokenizer(values.initiatives)
      )
      initiativesSearchObj = index.fieldSearch(
        initiatives,
        'initiatives',
        config
      )
      // console.log("initiatives Found");
    }
    // If empty array return all results
    else {
      initiativesSearchObj = index.documentStore.docs
      // console.log("No initiatives");
    }

    // Take all resulting objects and get Venn Diagram overlap. NOTE* this works on multiple values. Using two to keep demo simple. This should also be made dynamic using a loop to define the query-able fields, or potentially based on the fieldSearchObject that is being returned below, and pulled out of function scope using the previous declaration.
    const intersection = _.intersection(
      _.keys(querySearchObj),
      _.keys(themesSearchObj),
      _.keys(plansSearchObj),
      _.keys(initiativesSearchObj)
    )

    if (values.sort === 'datePublished') {
      setResults(
        intersection
          .map(key => index.documentStore.getDoc(key))
          .sort(
            (a, b) => parseFloat(b.datePublished) - parseFloat(a.datePublished)
          )
      )
      //console.log('datePublished sort condition working!')
    } else {
      setResults(
        _.sortBy(
          intersection.map(key => index.documentStore.getDoc(key)),
          values.sort
        )
      )
    }

    fieldSearchObject = {
      q: querySearchObj,
      themes: themesSearchObj,
      plans: plansSearchObj,
      initiatives: initiativesSearchObj,
    }
    // console.log(fieldSearchObject);
  }

  const sort = values => {
    if (values.sort === 'datePublished') {
      //console.log(results)
      setResults(
        results.sort(
          (a, b) => parseFloat(b.datePublished) - parseFloat(a.datePublished)
        )
      )
      //console.log('datePublished sort condition working!')
    } else {
      setResults(_.sortBy(results, values.sort))
    }
    // console.log(values);
  }

  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(results.length / resultsPerPage); i += 1) {
    pageNumbers.push(i)
  }

  // Starts the actual rendering of the markup.
  return (
    <>
      {/* Formik is used to simplify the form setup. It manages state internally and therefore requires composition to get state out. See Component^2 from Reach. */}
      <Formik
        initialValues={initialValues}
        initialStructure={initialStructure}
        render={({ values, resetForm }) => (
          <Form>
            <section className="flex">
              <div
                className="flex items-center h-12 w-full mb-10 bg-white text-black"
                style={{ boxShadow: 'inset 0 0 10px #d7d9d8' }}
              >
                <label htmlFor="q" className="w-10 h-full p-2 ml-2">
                  <SVGMagnifyingGlass />
                </label>
                <Field className="mr-4 w-full h-8" type="text" name="q" />
              </div>
            </section>
            <section className="flex">
              <aside className="hidden md:flex md:flex-col md:pr-3 md:w-1/3">
                <label
                  className="text-pink text-2xl py-2 font-header uppercase"
                  htmlFor="themes"
                >
                  Themes
                </label>
                <FieldArray
                  name="themes"
                  // Array helper is present to help with internal state of checkboxes yeilding an array vs individual booleans.
                  render={arrayHelpers => (
                    <div>
                      {themes.map(themes => (
                        <div key={themes.id}>
                          <label className="font-body text-black-darker">
                            <input
                              name="themes"
                              type="checkbox"
                              value={themes.id}
                              checked={values.themes.includes(themes.id)}
                              onChange={e => {
                                if (e.target.checked)
                                  arrayHelpers.push(themes.id)
                                else {
                                  const idx = values.themes.indexOf(themes.id)
                                  arrayHelpers.remove(idx)
                                }
                                arrayHelpers.form.setFieldValue('page', 1)
                              }}
                            />{' '}
                            {themes.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <label
                  className="text-pink text-2xl py-2 font-header uppercase"
                  htmlFor="plans"
                >
                  Plans
                </label>
                <FieldArray
                  name="plans"
                  // Array helper is present to help with internal state of checkboxes yeilding an array vs individual booleans.
                  render={arrayHelpers => (
                    <div>
                      {plans.map(plans => (
                        <div key={plans.id}>
                          <label className="font-body text-black-darker">
                            <input
                              name="plans"
                              type="checkbox"
                              value={plans.id}
                              checked={values.plans.includes(plans.id)}
                              onChange={e => {
                                if (e.target.checked)
                                  arrayHelpers.push(plans.id)
                                else {
                                  const idx = values.plans.indexOf(plans.id)
                                  arrayHelpers.remove(idx)
                                }
                                arrayHelpers.form.setFieldValue('page', 1)
                              }}
                            />{' '}
                            {plans.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <label
                  className="text-pink text-2xl py-2 font-header uppercase"
                  htmlFor="initiatives"
                >
                  Initiative
                </label>
                <FieldArray
                  name="initiatives"
                  // Array helper is present to help with internal state of checkboxes yeilding an array vs individual booleans.
                  render={arrayHelpers => (
                    <div>
                      {initiatives.map(initiatives => (
                        <div key={initiatives.id}>
                          <label className="font-body text-black-darker">
                            <input
                              name="initiatives"
                              type="checkbox"
                              value={initiatives.id}
                              checked={values.initiatives.includes(
                                initiatives.id
                              )}
                              onChange={e => {
                                if (e.target.checked)
                                  arrayHelpers.push(initiatives.id)
                                else {
                                  const idx = values.initiatives.indexOf(
                                    initiatives.id
                                  )
                                  arrayHelpers.remove(idx)
                                }
                                arrayHelpers.form.setFieldValue('page', 1)
                              }}
                            />{' '}
                            {initiatives.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                />
                {/* The search object is only here to provide us with a holistic look of each of the various search fields */}
                {/* <label className="py-2 font-bold">Search Query Object</label>
                <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </aside>
              <section ref={resultsRef} className="w-full md:pl-3 md:w-2/3">
                <div className="flex flex-col-reverse md:flex-row">
                  <button
                    className="w-3/4 mx-auto md:w-auto md:mx-0 bg-pink hover:bg-pink-lighter hover:text-pink-darkest text-white font-medium text-xl border-4 border-pink py-2 px-6 font-header uppercase"
                    type="button"
                    onClick={() =>
                      resetForm({
                        q: '',
                        themes: [],
                        plans: [],
                        initiatives: [],
                        sort: 'title',
                        page: '1',
                      })
                    }
                  >
                    Clear Filters
                  </button>
                  {/* Extra styling outside of tailwind is done in src/style/style.css */}
                  <Menu>
                    <MenuButton className="w-3/4 mx-auto md:mr-0 md:w-auto bg-orange hover:bg-orange-light border-4 border-orange hover:text-orange-darker text-white font-medium text-xl py-2 px-6 font-header uppercase mb-2 md:mb-0">
                      Sort By{' '}
                      <span className="pl-2 md:pl-32" aria-hidden>
                        ▾
                      </span>
                    </MenuButton>
                    <Field
                      name="sort"
                      render={({ form }) => (
                        <MenuList className="block bg-white whitespace-no-wrap outline-none">
                          <MenuItem
                            className="bg-orange-light hover:bg-orange text-white hover:text-white px-3 py-2 border-t-1 border-b-1 border-white"
                            onSelect={() => {
                              form.setFieldValue('sort', 'title')
                              form.setFieldValue('page', 1)
                              sort(values)
                            }}
                          >
                            Title
                          </MenuItem>
                          <MenuItem
                            className="bg-orange-light hover:bg-orange text-white hover:text-white px-3 py-2 border-b-1 border-white"
                            onSelect={() => {
                              form.setFieldValue('sort', 'modified')
                              form.setFieldValue('page', 1)
                              sort(values)
                            }}
                          >
                            Date Modified
                          </MenuItem>
                          <MenuItem
                            className="bg-orange-light hover:bg-orange text-white hover:text-white px-3 py-2"
                            onSelect={() => {
                              form.setFieldValue('sort', 'datePublished')
                              form.setFieldValue('page', 1)
                              sort(values)
                            }}
                          >
                            Date Published
                          </MenuItem>
                        </MenuList>
                      )}
                    />
                  </Menu>
                  {/* <button
                    className="w-3/4 mx-auto md:w-auto bg-blue hover:bg-blue-lighter hover:text-blue-darker text-white font-medium text-xl border-4 border-blue py-2 px-6 font-header uppercase block md:hidden mb-2 md:mb-0"
                    type="button"
                  >
                    Advanced Search
                  </button> */}
                </div>
                {/* This is where things get a bit complicated */}
                {/* In order to provide side-effects using the internal state from Formik we need a means of extracting that state, and managing data as it changes. Hence the use of Reach Component Component. This allows us to use the current state of the inputs in Formik to say... set the url query! --- It is also used to trigger search without the need to submit the form. It watches for changes and runs the function accordingly. */}
                <Component
                  values={values} // {render.values}
                  getInitialState={() => {
                    search(values)
                    setCurrentPage(values.page)
                  }}
                  didUpdate={({ prevProps, props }) => {
                    // console.log(values);
                    // Start work here for the day... const controlState = { page: currentPage, sort: sortBy };
                    const generatedQueryString = queryString.stringify(values, {
                      arrayFormat: 'index',
                    })
                    if (typeof window !== 'undefined') {
                      // Fixes Gatsby build which doesn't allow for window in SSR.
                      if (window.history.replaceState) {
                        const urlWithQuery = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${generatedQueryString}`
                        window.history.replaceState(
                          { path: urlWithQuery },
                          '',
                          urlWithQuery
                        )
                      }
                    }
                    // console.log(prevProps.values);
                    // console.log(props.values);
                    if (prevProps.values !== props.values) {
                      search(values)
                      setCurrentPage(values.page)
                    }
                  }}
                >
                  <ul className="w-full mt-6">
                    {currentResults.length > 0 ? (
                      trail.map(({ to, from, ...rest }, index) => {
                        //console.log(currentResults[index])
                        return (
                          <animated.li
                            key={currentResults[index]}
                            style={{ ...rest, to, from }}
                          >
                            <ResourceTemplate
                              buttonText={currentResults[index].button_text}
                              link={currentResults[index].link}
                              title={currentResults[index].title}
                              themes={currentResults[index].themes}
                              plans={currentResults[index].plans}
                              initiatives={currentResults[index].initiaitves}
                              source={currentResults[index].source}
                              description={currentResults[index].description}
                              datePublished={
                                currentResults[index].datePublished
                              }
                            />
                          </animated.li>
                        )
                      })
                    ) : (
                      <li>
                        <h3>
                          No results in the Metro Vision resources match your
                          search. Would you like to continue your search on
                          DRCOG.org?
                        </h3>
                        {values.q ? (
                          <CommonLink
                            className="text-purple"
                            to={`https://drcog.org/search?search_api_views_fulltext=${values.q}`}
                          >
                            Search DRCOG.org for {values.q}
                          </CommonLink>
                        ) : (
                          <CommonLink
                            className="text-purple"
                            to="https://drcog.org/search"
                          >
                            Search DRCOG.org
                          </CommonLink>
                        )}
                      </li>
                    )}
                  </ul>
                </Component>
                <Field
                  name="page"
                  render={({ form }) => (
                    <ul
                      id="page-numbers"
                      className="font-body text-black-darker flex list-reset mt-6"
                    >
                      {/* <li
                        className={`block hover:text-white hover:bg-blue px-3 py-2 ${
                          currentPage === number
                            ? 'text-white bg-blue'
                            : 'bg-black-lightest'
                        }`}
                        onClick={e => {
                          form.setFieldValue('page', values.page - 1);
                          scrollToRef(resultsRef);
                        }}
                      /> */}
                      {currentPage > 1 && (
                        <li
                          className={`block hover:text-white hover:bg-blue px-3 py-2 ${'bg-black-lightest'}`}
                          onClick={() => {
                            form.setFieldValue('page', Number(currentPage - 1))
                            scrollToRef(resultsRef)
                          }}
                        >
                          &lt;
                        </li>
                      )}
                      {pageNumbers.map(number => (
                        <li
                          className={`block hover:text-white hover:bg-blue px-3 py-2 ${
                            currentPage === number
                              ? 'text-white bg-blue'
                              : 'bg-black-lightest'
                          }`}
                          key={number}
                          id={number}
                          onClick={e => {
                            form.setFieldValue('page', Number(e.target.id))
                            scrollToRef(resultsRef)
                          }}
                        >
                          {number}
                        </li>
                      ))}
                      {pageNumbers.length > currentPage && (
                        <li
                          className={`block hover:text-white hover:bg-blue px-3 py-2 ${'bg-black-lightest'}`}
                          onClick={() => {
                            form.setFieldValue('page', Number(currentPage + 1))
                            scrollToRef(resultsRef)
                          }}
                        >
                          &gt;
                        </li>
                      )}
                      {/* <li
                        className={`block hover:text-white hover:bg-blue px-3 py-2 ${'bg-black-lightest'}`}
                        onClick={e => {
                          form.setFieldValue('page', values.page - 1);
                          scrollToRef(resultsRef);
                        }}
                      /> */}
                    </ul>
                  )}
                />
              </section>
            </section>
          </Form>
        )}
      />
    </>
  )
}

FormikSearch.propTypes = {
  location: PropTypes.object,
  searchIndex: PropTypes.object,
}
